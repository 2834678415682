<template>
  <div v-if="page" class="add-new-item-admin" >
    <title-bar
      submitBtn
      :submitBtnTitle="$t('page.update')"
      :title="$t('page.update')"
      @submitPressed="submitPressed"
    />
    <page-form :page-obj="page" :action="action" @clearAction="action = null" />

    <b-card>
      <custom-fields ownerResource="WEB_PAGE" :ownerId="page.id" :existingCustomFields="page.customFields" />
    </b-card>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import PageForm from './components/PageForm.vue';
import CustomFields from "@/components/CustomFields/customFields.vue";

export default {
  components: {
    TitleBar,
    PageForm,
    CustomFields
  },
  data() {
    return {
      action: null,
      page: null
    }
  },
  created() {
    this.loadPage()
  },
  methods: {
    loadPage() {
      const id = this.$route.params.id;
      this.$WebPages.getResource({ id }).then((response) => {
        this.page = response.data
        if(response.data.parent) {
          this.page.parent = {
            id: response.data.parent,
            label: response.data.parentName
          }
        }
      })
    },
    submitPressed() {
      this.action = 'update'
    }
  }
};
</script>

<style lang="scss">
</style>
